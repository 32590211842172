.sponsor {
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(136, 136, 136, 0.35);
  border-radius: 20px;
  margin: 0.5rem 0.5rem;
  background-color: white;
  box-shadow: 0px 3px 16px 0px rgba(136, 136, 136, 0.35) inset;
}
