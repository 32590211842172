#main {
  .breadcrumbs {
    padding: 10px 0;
    @media only screen and (min-width: $tablet-breakpoint) {
      @include add-decoration(right, true);
    }

    .breadcrumb {
      max-width: var(--default-container-width);
      margin-right: 5px;
      margin-left: 5px;
      color: var(--breadcrumbs-text-color) !important;
      text-transform: uppercase;

      a {
        color: var(--breadcrumbs-text-color) !important;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .divider::before {
        color: var(--breadcrumbs-text-color);
        content: '▲';
        font-size: 12px;
      }
    }
  }
}
