:root {
  --teaser-images-aspect-ratio: #{$teaser-images-aspect-ratio};
  --narrow-container-width: 1024px;
  --default-container-width: 1024px;
  --layout-container-width: 1440px;
  --plone-color: rgb(0, 131, 190);
  --plone-on-color: rgb(255, 255, 255);
  --decoration-background: #f2f4f5;
  --breadcrumbs-text-color: #000;
  --decoration-image: url(./static/bg_image_03.png);
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-bolder: 900;
  --font-size-2xs: 12px;
  --font-size-xs: 14px;
  --font-size-s: 18px;
  --font-size-m: 21px;
  --font-size-l: 24px;
  --font-size-xl: 30px;
  --font-size-2xl: 33px;
  --font-size-3xl: 36px;
  --font-size-4xl: 42px;
  --font-size-5xl: 48px;
  --font-size-6xl: 56px;
  --line-height-xs: 16px;
  --line-height-s: 18px;
  --line-height-m: 24px;
  --line-height-l: 30px;
  --line-height-xl: 33px;
  --line-height-2xl: 36px;
  --line-height-3xl: 42px;
  --line-height-4xl: 48px;
  --line-height-5xl: 56px;
  --line-height-6xl: 64px;
}

a {
  color: $link-color;
  text-decoration: solid;

  &:hover {
    color: $link-color-hover;
    text-decoration: solid;
  }
}

#page-controlpanel-layout {
  max-width: var(--default-container-width);
  margin-right: auto;
  margin-left: auto;
}
